import { createRouter, createWebHistory } from "vue-router";
import App from "./App.vue";

const routes = [
  {
    path: "/",
    name: "app",
    component: App,
    children: [
      {
        path: "",
        name: "dashboard",
        component: () => import("./pages/Dashboard.vue"),
      },
      {
        path: "new-project",
        name: "new-project",
        component: () => import("./pages/NewProject.vue"),
      },
      {
        path: ":projectId/:regionId/ChemicalFertility",
        name: "chemicalFertility",
        component: () => import("./pages/ChemicalFertility.vue"),
      },
      {
        path: ":projectId/:regionId/Attachments",
        name: "Attachments",
        component: () => import("./pages/Attachments.vue"),
      },
      {
        path: ":projectId/:regionId/FunctionalAnalyzes",
        name: "functionalAnalyzes",
        component: () => import("./pages/FunctionalAnalyzes.vue"),
      },
      {
        path: ":projectId/:regionId/LeafAnalysis",
        name: "leafAnalysis",
        component: () => import("./pages/LeafAnalysis.vue"),
      },
      {
        path: ":projectId/:regionId/summary",
        name: "summary",
        component: () => import("./pages/Summary.vue"),
      },
      {
        path: ":projectId/:regionId/secondaryBenefits",
        name: "secondaryBenefits",
        component: () => import("./pages/SecondaryBenefits.vue"),
      },
      {
        path: ":projectId/:regionId/pathogenic",
        name: "pathogenic",
        component: () => import("./pages/Pathogenic.vue"),
      },
      {
        path: ":projectId/:regionId/microorganisms",
        name: "microorganisms",
        component: () => import("./pages/Microorganisms.vue"),
      },
      {
        path: ":projectId/:regionId/leafCorrelation",
        name: "leafCorrelation",
        component: () => import("./pages/LeafCorrelation.vue"),
      },
      {
        path: ":projectId/:regionId/growthpromoters",
        name: "growthpromoters",
        component: () => import("./pages/GrowthPromoters.vue"),
      },
      {
        path: ":projectId/:regionId/biodiversity",
        name: "biodiversity",
        component: () => import("./pages/Biodiversity.vue"),
      },
      {
        path: ":projectId/:regionId/environmentalAnalysis",
        name: "environmentalAnalysis",
        component: () => import("./pages/EnvironmentalAnalysis.vue"),
      },
      {
        path: ":projectId/:regionId/communityComposition",
        name: "communityComposition",
        component: () => import("./pages/CommunityComposition.vue"),
      },
      {
        path: ":projectId/:regionId/betaDiversity",
        name: "betaDiversity",
        component: () => import("./pages/BetaDiversity.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("./pages/Login.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
