import { defineStore } from "pinia";
export const useProjectsStore = defineStore("project", {
  state: () => ({
    pro: [
      {
        id: 1,
        typeProject: "01",
        name: "Projeto A",
        regions: [
          { id: 1, name: "Região 1" },
          { id: 2, name: "Região 2" },
        ],
        finished: true,
        coordinates: [{ lat: -23.5505, lng: -46.6333 }],
      },
      {
        id: 2,
        typeProject: "02",
        name: "Projeto B",
        regions: [{ id: 3, name: "Região 1" }],
        finished: true,
        coordinates: [{ lat: -25.5505, lng: -42.6333 }],
      },
      {
        id: 3,
        typeProject: "02",
        name: "Projeto C",
        regions: [{ id: 4, name: "Região 1" }],
        finished: false,
        coordinates: [{ lat: -21.5505, lng: -41.6333 }],
      },
    ],
  }),
  getters: {
    projects: (state) => state.pro,
    project: (state) => (id) => state.pro.find((project) => project.id == id),
  },
  actions: {},
});
