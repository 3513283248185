<template>
  <div class="layout-footer">
    de
    <span class="font-medium ml-2">Biome4All</span>
  </div>
</template>

<script>
export default {
  name: "AppFooter",
  methods: {},
  computed: {},
};
</script>
