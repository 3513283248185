<template>
  <div :class="containerClass" @click="onWrapperClick">
    <AppTopBar @menu-toggle="onMenuToggle" />
    <div class="layout-sidebar" @click="onSidebarClick">
      <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
      <AppMenu :model="projectMenus" @menuitem-click="onMenuItemClick" />
    </div>

    <div class="layout-main-container">
      <div class="layout-main">
        <router-view />
      </div>
      <AppFooter />
    </div>

    <transition name="layout-mask">
      <div
        class="layout-mask p-component-overlay"
        v-if="mobileMenuActive"
      ></div>
    </transition>
  </div>
</template>

<script>
import AppTopBar from "./AppTopbar.vue";
import AppMenu from "./AppMenu.vue";
import AppFooter from "./AppFooter.vue";
import { useProjectsStore } from "@/store/projects";

export default {
  data() {
    return {
      projectsStore: useProjectsStore(),
      layoutMode: "static",
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      menu: [
        {
          label: "Início",
          items: [
            {
              label: "Dashboard",
              icon: "pi pi-fw pi-home",
              to: "/",
            },
          ],
        },
      ],
    };
  },
  watch: {
    $route() {
      this.menuActive = false;
      this.$toast.removeAllGroups();
    },
  },
  methods: {
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === "overlay") {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        } else if (this.layoutMode === "static") {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    addClass(element, className) {
      if (element.classList) element.classList.add(className);
      else element.className += " " + className;
    },
    removeClass(element, className) {
      if (element.classList) element.classList.remove(className);
      else
        element.className = element.className.replace(
          new RegExp(
            "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
            "gi"
          ),
          " "
        );
    },
    isDesktop() {
      return window.innerWidth >= 992;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === "static") return !this.staticMenuInactive;
        else if (this.layoutMode === "overlay") return this.overlayMenuActive;
      }

      return true;
    },
  },
  computed: {
    projectMenus() {
      var projects = this.projectsStore.projects;
      var finished = projects.filter((p) => p.finished);
      var pending = projects.filter((p) => !p.finished);
      const items = (projectId, regionId) => [
      /*  {
          label: "Resumo",
          icon: "pi pi-fw pi-folder",
          to: `/${projectId}/${regionId}/summary`,
        },*/
        {
          label: "Benefícios Secundários",
          icon: "pi pi-fw pi-folder",
          to: `/${projectId}/${regionId}/secondaryBenefits`,
        },
        {
          label: "Produtividade e nutrição foliar",
          icon: "pi pi-fw pi-folder",
          to: `/${projectId}/${regionId}/leafCorrelation`,
        },
        {
          label: "Posicionamento de produto",
          icon: "pi pi-fw pi-folder",
          to: `/${projectId}/${regionId}/environmentalAnalysis`,
        },
        {
          label: "Biodiversidade",
          icon: "pi pi-fw pi-folder",
          to: `/${projectId}/${regionId}/biodiversity`,
        },
        /*{
          label: "Beta-diversidade",
          icon: "pi pi-fw pi-folder",
          to: `/${projectId}/${regionId}/betaDiversity`,
        },*/
        {
          label: "Promotores de Crescimento",
          icon: "pi pi-fw pi-folder",
          to: `/${projectId}/${regionId}/growthpromoters`,
        },
        {
          label: "Patogênicos",
          icon: "pi pi-fw pi-folder",
          to: `/${projectId}/${regionId}/pathogenic`,
        },
        {
          label: "Micro-organismos de ocorrência única",
          icon: "pi pi-fw pi-folder",
          to: `/${projectId}/${regionId}/microorganisms`,
        },
        {
          label: "Composição da Comunidade",
          icon: "pi pi-fw pi-folder",
          to: `/${projectId}/${regionId}/communityComposition`,
        },
        {
          label: "Anexos",
          icon: "pi pi-fw pi-folder",
          to: `/${projectId}/${regionId}/Attachments`,
        },
      ];

      const itemsfinished = (projectId, regionId) => [
        {
          label: "Fertilidade Química",
          icon: "pi pi-fw pi-folder-open",
          to: `/${projectId}/${regionId}/ChemicalFertility`,
        },
        {
          label: "Analise Foliar",
          icon: "pi pi-fw pi-folder-open",
          to: `/${projectId}/${regionId}/LeafAnalysis`,
        },
      ];

      const menu = [
        finished.length
          ? {
              label: "Projetos Finalizados",
              icon: "pi pi-fw pi-search",
              items: finished.map((project) => ({
                label: project.name,
                icon: "pi pi-fw pi-folder",
                items: project.regions.map((r) => ({
                  label: r.name,
                  icon: "pi pi-fw pi-folder",
                  items: items(project.id, r.id),
                })),
              })),
            }
          : undefined,
        pending.length
          ? {
              label: "Projetos em Andamento",
              icon: "pi pi-fw pi-search",
              items: pending.map((project) => ({
                label: project.name,
                icon: "pi pi-fw pi-bookmark",
                items: project.regions.map((r) => ({
                  label: r.name,
                  icon: "pi pi-fw pi-map",
                  items: itemsfinished(project.id, r.id),
                })),
              })),
            }
          : undefined,
      ];

      return [
        ...menu,
        {
          label: "Novos Projetos",
          icon: "pi pi-fw pi-search",
          items: [
            {
              label: "Criar novo projeto",
              icon: "pi pi-fw pi-bookmark",
              to: "/new-project",
            },
          ],
        },
      ];
    },

    containerClass() {
      return [
        "layout-wrapper",
        {
          "layout-overlay": this.layoutMode === "overlay",
          "layout-static": this.layoutMode === "static",
          "layout-static-sidebar-inactive":
            this.staticMenuInactive && this.layoutMode === "static",
          "layout-overlay-sidebar-active":
            this.overlayMenuActive && this.layoutMode === "overlay",
          "layout-mobile-sidebar-active": this.mobileMenuActive,
          "p-input-filled": this.$primevue.config.inputStyle === "filled",
          "p-ripple-disabled": this.$primevue.config.ripple === false,
        },
      ];
    },
    logo() {
      return this.$appState.darkTheme
        ? "//images/logo-white.svg"
        : "//images/logo.svg";
    },
  },
  beforeUpdate() {
    if (this.mobileMenuActive)
      this.addClass(document.body, "body-overflow-hidden");
    else this.removeClass(document.body, "body-overflow-hidden");
  },
  components: {
    AppTopBar: AppTopBar,
    AppMenu: AppMenu,
    AppFooter: AppFooter,
  },
};
</script>

<style lang="scss">
@import "./App.scss";
</style>
