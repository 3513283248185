<template>
  <div class="layout-topbar" style="height: 81px">
    <router-link to="/" class="layout-topbar-logo">
      <img alt="Logo" style="" :src="topbarImage()" />
    </router-link>
    <button
      class="p-link layout-menu-button layout-topbar-button"
      @click="onMenuToggle"
    >
      <i class="pi pi-bars" style="color: white"></i>
    </button>
    <button
      class="p-link layout-topbar-menu-button layout-topbar-button"
      v-styleclass="{
        selector: '@next',
        enterClass: 'hidden',
        enterActiveClass: 'scalein',
        leaveToClass: 'hidden',
        leaveActiveClass: 'fadeout',
        hideOnOutsideClick: true,
      }"
    >
      <i class="pi pi-ellipsis-v"></i>
    </button>
    <img
      alt="center"
      style="width:70px height:50px "
      :src="centerImage()"
      class="layout-topbar-menu lg:flex origin-top"
    />
    <ul class="layout-topbar-menu hidden lg:flex origin-top">
      <li>
        <button class="p-link layout-topbar-button">
          <i class="pi pi-cog" style="color: white"></i>
          <span>Configurações</span>
        </button>
      </li>
      <li>
        <button class="p-link layout-topbar-button">
          <i class="pi pi-user" style="color: white"></i>
          <span>Perfil</span>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  methods: {
    onMenuToggle(event) {
      this.$emit("menu-toggle", event);
    },
    onTopbarMenuToggle(event) {
      this.$emit("topbar-menu-toggle", event);
    },
    topbarImage() {
      return this.$appState.darkTheme
        ? "/images/logo-dark.svg"
        : "/images/logo-white.svg";
    },
    centerImage() {
      return this.$appState.darkTheme
        ? "/images/center-dark.svg"
        : "/images/center-white.svg";
    },
  },
  computed: {
    darkTheme() {
      return this.$appState.darkTheme;
    },
  },
};
</script>
